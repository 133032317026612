import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppContext } from "hooks/appContext"
import useGeolocation from 'react-hook-geolocation'
import ErrorMsg from 'components/errormsg/ErrorMsg';
import Confirmation from "./Confirmation";
import { apiPost, getFromApi } from "data/Api";

import 'assets/styles/person.scss';
import "./EnterExit.scss";

const EnterExit = () => {
    let { childid } = useParams();
    const { getChild } = useAppContext();
    const geolocation = useGeolocation();
    const [confirmed, setConfirmation] = useState(0);
    const [errorStatus, setErrorStatus] = useState(0);
    const [distance, setDistance] = useState(-1);

    const hasFetchedData = useRef(false);


    let child = getChild(childid);
    function checkIn() {
        doAction(1);
    }

    function checkOut() {
        doAction(2);
    }

    function error(status) {
        setErrorStatus(status);
    }

    function doAction(a) {
        const data = {
            token: child.token,
            unitid: child.unitid.toString(),
            childid: child.childid,
            lon: !geolocation.error ? geolocation.longitude.toString() : null,
            lat: !geolocation.error ? geolocation.latitude.toString() : null
        };

        apiPost(a === 1 ? "/api/CheckIn" : "/api/CheckOut", data, () => setConfirmation(a), error);
    }

    function errorMessage() {
        let ico = "genError";
        let msg = null;
        switch (errorStatus) {
            case 406: msg = "W dni wolne ta akcja nie jest dozwolona."; break;
            case 409: msg = "Twoja lokalizacja nie wskazuje na obecność w pobliżu placówki."; break;
            case 429: msg = "Przyprowadzenie dziecka zostało już wcześniej zgłoszone."; break;
            case 430: msg = "Odebranie dziecka zostało już wcześniej zgłoszone."; break;
            default: msg = "Błąd"
        }
        
        return (
            <>
                <ErrorMsg msg={msg} icon={ico} />
                <div className="mt-3">
                    <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (!childid || !geolocation || !geolocation.longitude || !geolocation.latitude)
            return;


        if (!hasFetchedData.current) {
            hasFetchedData.current = true;
            const unitid = child.unitid;
            const lon = !geolocation.error ? geolocation.longitude : null;
            const lat = !geolocation.error ? geolocation.latitude : null;

            const apiSuccess = (data) => {
                setDistance(data.distance);
            }
            console.log("Location " + lat + " " + lon);

            getFromApi("/api/Location?unitid=" + unitid + "&lat=" + lat + "&lon=" + lon, apiSuccess, () => 0);
        }
    }, [childid, child, geolocation]);

    return (
        <div className="container presence-container">
    
            {geolocation.error ?
                (<ErrorMsg msg="Włącz lokalizację GPS w twoim telefonie." icon="gpsError" />)
                :
                (
                    errorStatus !== 0 || !child ?
                        <div>{errorMessage()}</div>
                    :
                    (confirmed === 0 ?
                        (<div className="childItem form-narrow mt-3">
                            <span className="hdr-name">Zgłoś wejście/wyjście</span>
                            <div>Odległość: {distance >= 0 ? distance + "m" : "?"}</div>
                            <div className={child.sex === 1 ? "person male" : "person female"}></div>
                            <span className="name">{child.name}</span>
                            <button id="btnIn" className="btn btn-primary btnIn mb-3" onClick={checkIn}>wejście</button>
                            <button id="btnOut" className="btn btn-success btnOut mb-3" onClick={checkOut}>wyjście</button>
                            <div className="mb-3">
                                <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                            </div>
                        </div>)
                        :
                        (
                            <Confirmation childName={child.name} action={confirmed === 1 ? "przyjęte" : "odebrane"}/>
                        )
                    )
                )
            }
        </div>
    )
};

export default EnterExit;