import React from 'react';
import { Link, useParams, useHistory, useLocation   } from 'react-router-dom';
import { useAppContext } from "hooks/appContext"
import { apiPost } from "data/Api";

import Day from './Day';
import Moment from 'react-moment';
import moment from 'moment';


import './Absence.scss';

const Absence = () => {
    let { childid, month } = useParams();
    const { getChild } = useAppContext();


    const weekDays = ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'];

    const date = moment().date(1).add(month, 'M');
    const history = useHistory();
    const location = useLocation();


    let startDay = date.day();
    if (startDay === 0)
        startDay = 7;
    const days = date.endOf('month').date() + startDay - 1;
    const childData = getChild(childid);

    function success(data) {
        history.push("/Kalendarz/" + childid);
    }

    function error(status) {
        console.log("Error " + status);
    }

    function saveData() {
        
        let list = document.querySelectorAll(".absence-on");

        let selDays = [];
        let offDays = [];

        list.forEach(function (item) {
            selDays.push(parseInt(item.innerText));
        });

        offDays = location.state.absence.filter(x => !selDays.includes(x));



        if (selDays.length > 0 || offDays.length > 0) {

            let data = {
                token: childData.token,
                childid: childid,
                unitid: childData.unitid.toString(),
                month: month,
                year: date.year(),
                days: selDays,
                daysoff: offDays
            };

            apiPost("/api/SetAbsence", data, success, error);
        }
    }

    return (
        <div className='calendar-wrapper'>
            <h4 className='child-title'>Zgłoszenie nieobecności:<span className='child-data'>{childData.name}</span></h4>
            <div className='absence-wrapper'>
                <p className="mt-auto mb-auto">Zaznacz dni nieobecności</p>
                <div className="button-group">
                    <button type="button" id="btnApply" className="btn btn-primary" onClick={saveData}>Zapisz</button>
                    <Link to={"/Kalendarz/" + childid} id="btn-return" className="btn btn-return">Anuluj</Link>
                </div>
            </div>

            <div className='calendar'>
                <div className='calendar-head'>
                    <div className='buttons-wrapper'>
                        <span></span>
                        <span> <Moment locale="pl" format="MMMM YYYY">{date}</Moment></span>
                        <span></span>
                    </div>
                </div>
                <div className="calendar-content">
                    <ul className='weekday-wrapper'>
                        {weekDays.map(i => (
                            <li key={i} className='weekday'>{i}</li>
                        ))}
                    </ul>
                    <ul className='days-list'>
                    { new Array(days).fill(null)
                            .map((_, index) => {
                                const d = index - startDay + 2;
                                return (
                                    <Day key={index}
                                        day={d}
                                        index={index % 7}
                                        month={month}
                                        startDay={moment().date()}
                                        absenece={location.state.absence.includes(d)} />
                                );
                        })
                    }
                    </ul>
                </div>
            </div>

            <div className="calendar-legend">
                <div className="dot-container">
                    <div className="dot-absence"></div>
                    <span className="dot-description"> - zgłoszona nieobecność</span>
                </div>
            </div>
        </div>

    )
};

export default Absence;