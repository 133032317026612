import React from 'react';

import './Day.scss'


const Day = ({ index, day, presence, selected, setSelectedDay }) => {
    const d = day;
    const holiday = index >= 5;
    let bclass = holiday ?  "none" : "";
    let selclass = selected ? "selday " : "";
    let numclass = holiday ? "holiday" : "";

    if (presence != null) {
        bclass = "badge-ok";

        if (presence.absence && !holiday) {
            numclass = "absence-on " + numclass;
            bclass = "badge-none";
        }
        else if (presence.PresenceType === 0 && presence.to.length === 0)
            bclass = "badge-in";
        else if (presence.paidHours > 0)
            bclass = "badge-over";
    }
    if (bclass.length > 0)
        bclass += " ";

    function selectedDay() {
        setSelectedDay(day);
    }

    return (
        <li className={selclass + "day"} onClick={selectedDay}>
            <div className={numclass}>{d > 0 ? d : ' '}</div>
            {(d > 0) && <span className={bclass + 'badge'}>o</span>}
        </li>
    )
};

export default Day;