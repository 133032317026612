import React from 'react';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Link, useHistory } from 'react-router-dom';
import { apiPost } from "data/Api";
import { useAppContext } from "hooks/appContext"
import { useTranslation } from 'react-i18next';

import "./login.scss";

const Login = () => {
    const { register, formState: { errors, isSubmitting  }, handleSubmit, setError} = useForm();
    const history = useHistory();
    const { setLoggedin, dict } = useAppContext();
    const { t } = useTranslation();

    function apiError(status) {
        setError("btnLogin", {
            type: "manual",
            message: "Nieprawidłowy email lub hasło",
        });
    }

    function apiSuccess(status) {
        history.push("/");
        setLoggedin(true);
    }


    function onSubmit(data) {
        apiPost("/api/SignIn", data, apiSuccess, apiError);
    }



    const passowrdRegister = register("password", { required: "Nie podano hasła" });
    const emailRegister = register("email", {
        required: "Nie podano adresu email",
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            message: "Podaj prawidłowy adres email"
        }
    });



    return (
    <div className="main-wrapper">
        <div className="container presence-container">
                <form className="form-narrow" method="post" onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mt-3 mb-3">{t('login:header')}</h4>
                    <ErrorMessage
                        errors={errors}
                        name="btnLogin"
                        render={({ message }) => <div className="form-error-summary"><p className="error">{t('error')}</p>{message}</div>}
                    />

                <div className="mb-3">
                        <input type="text"
                            name="email"
                            placeholder="Adres email"
                            {...emailRegister}
                            className="form-control" 
                            autoComplete="new-email"
                            aria-required="true"
                        />
                        <div className="error">
                            {errors.email && errors.email.message}
                        </div>
                </div>
                <div className="mb-3">
                        <input type="password"
                            {...passowrdRegister}
                            className="form-control"
                            placeholder="Hasło"
                            autoComplete="new-password"
                        />
                        <div className="error">
                            {errors.password?.type === 'required' && errors.password.message}
                        </div>
                    </div>

                <div className="mb-3">
                        <button type="submit" id="btnLogin" {...register("btnLogin")} disabled={isSubmitting} className="btn btn-primary" >{t('buttons.login') }</button>
                </div>
                <div className="mb-3 d-flex justify-content-center">
                        <Link to="/Odzyskiwanie" id="" className="fw-bolder">{dict.login.recoverPassword}</Link>
                </div>
                <div className="mb-3">
                        <Link to="/" id="btn-return" className="btn btn-return">{t('buttons.return')}</Link>
                </div>
            </form>
        </div>
    </div>
    )
};

export default Login;