import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiPost, getFromApi } from "data/Api";


const NewPassword = () => {
    let { token } = useParams();
    const { register, handleSubmit, watch, formState: { errors }, setError } = useForm();
    const [valid, setValid] = useState(null);
    const history = useHistory();
 
    const password = useRef({});
    password.current = watch("newPassword", "");

    useEffect(() => {
        console.log(token);
        if(token && token.length > 100)
            getFromApi("/api/RestorePassword/" + token, () => { console.log("success"); setValid(true); }, () => setValid(false));
    }, [token]);


    function apiError(status) {
        setError("token", {
            type: "manual",
            message: "Zmiana hasła nie udała się!",
        });
    }

    function onSubmit(data) {
        apiPost("/api/Password", data, () => history.push("/Logowanie"), apiError);
    }


    return (
        <>
            <div className="main-wrapper">
                <div className="container presence-container">
                    { valid === false || !token || token.length < 100 ?
                        <div className="mt-4 mb-3 error">
                            <h4>Ten link jest niepoprawny lub utracił ważność.</h4>
                            <div className="mb-3">
                                <Link to="/Logowanie" id="btn-return" className="btn btn-return">Powrót</Link>
                            </div>
                        </div>
                        :
                        <form className="form-narrow" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <h4 className="mt-3 mb-3">Ustaw swoje nowe hasło</h4>

                            <ErrorMessage
                                errors={errors}
                                name="token"
                                render={({ message }) => <div className="form-error-summary"><p className="error">Wystąpił błąd</p>{message}</div>}
                            />

                            <div className='mb-3'>
                                <input
                                    type="password"
                                    name="Password"
                                    className="form-control"
                                    placeholder="Nowe hasło"
                                    {...register("newPassword", {
                                        required: "Nowe hasło nie zostało podane",
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                            message: "Hasło powinno zawierać co najmniej 1 literę i cyfrę."
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Hasło powinno zawierać nie mniej, niż 8 znaków."
                                        }
                                    })}
                                    autoComplete="new-password"
                                />
                                <div className="error">
                                    {errors.newPassword && errors.newPassword.message}
                                </div>
                            </div>

                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Potwierdź nowe hasło"
                                    {...register("confirmedNewPassword", { required: "Nowe hasło nie zostało podane", validate: (value) => { return value === password.current; } })}

                                    autoComplete="new-password"
                                />
                                <div className="error">
                                    {errors.confirmedNewPassword?.type === 'required' && errors.confirmedNewPassword.message}
                                    {errors.confirmedNewPassword?.type === 'validate' && "Nowe hasło i jego potwierdznie nie sa takie same"}
                                </div>
                            </div>

                            <input
                                type="hidden"
                                {...register("token")}
                                value={token}
                            />

                            <div className="mb-3">
                                <button type="submit" id="btnLogin" className="btn btn-primary">Zmień</button>
                            </div>
                            <div className="mb-3">
                                <Link to="/Logowanie" id="btn-return" className="btn btn-return">Powrót</Link>
                            </div>
                        </form>
                    }
                   
                </div>
            </div>
            
        </>
    )
};

export default NewPassword;