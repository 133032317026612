import React from 'react';
import { Link } from 'react-router-dom';

import 'assets/styles/person.scss';
import "./EnterExit.scss";

const Confirmation = ({childName, action}) => {
    const d = new Date();
    let now = d.getHours() + ":" + d.getMinutes();

    return (
        <div className="childItem">
            <div id="confirmation" className="form-narrow">
                <div className="person person-small male"></div>
                <span className="name">{childName}</span>
                <div className="clearfix"></div>
                <hr />
                <div id="confirm-label">Dziecko <span className="confirm-action">{action}</span> o godz.</div>
                <div id="confirm-hour" className="mt-3">{now}</div>
                <hr />
                <div className="mb-3">
                    <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                </div>
            </div>
        </div>
    )
};

export default Confirmation;