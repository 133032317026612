import React from 'react';

import './DayInfo.scss'

function DayInfo({ day, presence }) {

    return (
        <div className='dayInfo-wrapper'>
            {presence != null &&
                <div>
                    <div className='day-name'>
                        <label>{day.format('D MMMM')}</label>
                    </div>

                {presence.absence
                    ?
                    <div className="absenece-info">
                        <label>Nieobecność</label>
                    </div>
                    :
                     <>
                            <div className='checkin'>
                                <label>Wejście:</label>
                                <span>{presence && presence.from}</span>
                            </div>

                            <div className='checkout'>
                                <label>Wyjście:</label>
                                <span>{presence && presence.to}</span>
                            </div>
                    </>

                    }
                </div>
            }
        </div>        
    )
}

export default DayInfo;