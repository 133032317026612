import React  from 'react';

import "./ErrorMsg.scss";


const ErrorMsg = ({ msg, icon }) => {


    return (
        <div className={"presence-error " + icon ?? ""}>
            {msg}
        </div>
    )
};

export default ErrorMsg;