import React, { createContext, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from "history"
import LayoutWrapper from './containers/LayoutWrapper';
import Home from './pages/home/Home';
import Payments from './pages/payments/Payments';
import Login from './pages/login/login';
import Help from './pages/help/help';
import Activation from './pages/activation/Activation';
import Calendar from './pages/calendar/Calendar';
import ChangePassword from './pages/changepassword/ChangePassword';
import RestorePassword from './pages/restorepassword/RestorePassword';
import NewPassword from './pages/newpassword/NewPassword';
import Absence from './pages/absence/Absence';
import Meals from './pages/meals/Meals';
import EnterExit from './pages/enterexit/EnterExit';
import NotFound from './pages/notfound/NotFound';
import Messages from './pages/messages/Messages';
import Privacy from './pages/privacy/Privacy';
import localStorageData from 'data/LocalStorageData';

import 'moment/locale/pl';

const history = createBrowserHistory();

export const LoginContext = createContext(null);

const App = () => {
    const [userContext, setUserContext] = useState({ loggedin: false, children: localStorageData.load() });

    return (
        <LoginContext.Provider value={{ userContext, setUserContext }}>
            <Router history={history}>
                <LayoutWrapper>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/Platnosci" component={Payments} />
                        <Route path="/Logowanie" component={Login} />
                        <Route path="/Pomoc" component={Help} />
                        <Route path="/Aktywacja" component={Activation} />
                        <Route path="/Haslo" component={ChangePassword} />
                        <Route path="/Odzyskiwanie" component={RestorePassword} />
                        <Route path="/Jadlospis/:childid" component={Meals} />
                        <Route path="/Kalendarz/:childid" component={Calendar} />
                        <Route path="/Absencja/:childid/:month" component={Absence} />
                        <Route path="/Wejscie/:childid" component={EnterExit} />
                        <Route path="/Komunikaty" component={Messages} />
                        <Route path="/politykaprywatnosci" component={Privacy} />
                        <Route path="/nowehaslo/:token" component={NewPassword} />
                        <Route component={NotFound} />
                    </Switch>
                </LayoutWrapper>
            </Router>
        </LoginContext.Provider>
    );
}

export default App;