import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppContext } from "hooks/appContext"

import Day from './Day';
import DayInfo from './DayInfo';
import Moment from 'react-moment';
import { getFromApi } from "data/Api";
import moment from 'moment';

import absenceIcon from "assets/images/icon_cal_absence.png";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import './Calendar.scss';

const Calendar = () => {
    let { childid } = useParams();

    const weekDays = ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'];


    const currentDay = new Date().getDay();
    const [month, setMonth] = useState(0);
    const [presence, setPresence] = useState(null);
    const [selDay, setSelectedDay] = useState(null);
    const { getChild } = useAppContext();

    const date = moment().date(1).add(month, 'M');
    const year = date.year();
    const currentMonth = date.month();


    let startDay = date.day();
    if (startDay === 0)
        startDay = 7;
    const days = date.endOf('month').date() + startDay - 1;
    const childData = childid && getChild(childid);


    function prevMonth(e) {
        e.preventDefault();
        setMonth(month - 1);
    }

    function nextMonth(e) {
        e.preventDefault();
        setMonth(month + 1);
    }

    function apiError(error) {
        console.log("error: " + error);
    }

    function getPresenceInfo(day) {
        if (presence == null)
            return null;

        return presence.find(x => x.day === day);
    }



    useEffect(() => {
        if (!childid)
            return;

        const apiSuccess = (data) => {
            setPresence(data.list);

            if (month === 0)
                setSelectedDay(currentDay + 1);
        }

        setSelectedDay(null);
        getFromApi("/api/MonthPresence?childid=" + childid + "&month=" + currentMonth + "&year=" + year, apiSuccess, apiError);
    }, [childid, month, currentMonth, year, currentDay]);


    return (
        <div className='calendar-wrapper'>
            <h4 className='child-title'>Kalendarz obecności:<span className='child-data'>{childData ? childData.name : ""}</span></h4>

            { month >= 0 &&
            <div className='absence-wrapper'>
                <Link to={{ pathname: "/Absencja/" + childid + "/" + month, state: { absence: presence && presence.filter(x => x.absence).map(x => x.day) } }} className="absence-link">
                    <span className='absence-span'>Zgłoś nieobecność</span>
                    <img className='absence-img' alt="nieobecność" src={absenceIcon} />
                </Link>
            </div>
            }
            <div className='calendar'>
                <div className='calendar-head'>
                    <div className='buttons-wrapper'>
                        <a href="#!" onClick={prevMonth}><FaChevronLeft /></a>
                        <span> <Moment locale="pl" format="MMMM YYYY">{date}</Moment></span>
                        <a href="#!" onClick={nextMonth}><FaChevronRight /></a>
                    </div>
                </div>
                <div className="calendar-content">
                    <ul className='weekday-wrapper'>
                        {weekDays.map(i => (
                            <li key={i} className='weekday'>{i}</li>
                        ))}
                    </ul>
                    <ul className='days-list'>
                    { new Array(days).fill(null)
                            .map((_, index) => {
                                const d = index - startDay + 2;
                                const pinfo = getPresenceInfo(d);
                            return (
                                <Day key={index} day={d} index={index % 7} presence={pinfo} selected={d === selDay} setSelectedDay={setSelectedDay}/>
                            );
                        })
                    }
                    </ul>
                </div>
            </div>

            <DayInfo day={date.date(selDay)} presence={getPresenceInfo(selDay)} />

            <div className="calendar-legend">
                <div className="dot-container">
                    <div className="dot dot-green"></div>
                    <span className="dot-description"> - tylko godziny bezpłatne </span>
                </div>

                <div className="dot-container" >
                    <div className="dot dot-red"></div>
                    <span className="dot-description"> - godziny płatne</span>
                </div>

                <div className="dot-container">
                     <div className="dot dot-blue"></div>
                    <span className="dot-description"> - godziny w placówce</span>
                </div>
                <div className="dot-container">
                    <div className="absence"></div>
                    <span className="dot-description "> - zgłoszona nieobecność</span>
                </div>
            </div>


            <div className="mb-3">
                <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
            </div>

        </div>

    )
};

export default Calendar;