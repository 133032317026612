import React from 'react';

import '../calendar/Day.scss'

const Day = ({ index, day, month, startDay, absenece  }) => {
    const d = day;
    const m = parseInt(month);
    const holiday = index >= 5;

    let dclass = holiday ? "holiday" : (d > 0 && (m > 0 || (m === 0 && d >= startDay)) ? "absence-sel" : "");
    if (absenece)
        dclass += " absence-on";

    function selectedDay(e) {
        const cl = e.target.classList;
        if (cl.contains("absence-sel"))
            cl.toggle("absence-on");
    }

    return (
        <li className={"day"}>
            <div className={dclass} onClick={selectedDay}>{d > 0 ? d : ' '}</div>
        </li>
    )
};

export default Day;