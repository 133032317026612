import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from "hooks/appContext"
import { getFromApi } from "data/Api";

import "./messages.scss";

const Messages = () => {
    const LocalStrorageKey = "NaborLasMessage";

    const { loggedin } = useAppContext();

    const [messages, setMessages] = useState([]);

    function apiError(error) {
        console.log("error: " + error);
    }

	function apiSuccess(data) {
		if (data != null && data.length > 0) {
			const maxId = Math.max(...data.map((x) => x.id));
			localStorage.setItem(LocalStrorageKey, maxId);
		}

        setMessages(data);
    }

    useEffect(() => {
        getFromApi("/api/Messages", apiSuccess, apiError);
    }, [loggedin]);


    return (
        <div className="main-wrapper">
            <div className="container presence-container">
                <input type="hidden" id="NaborLasMessage" value="Model.MaxMessageId" />
                <h3>Komunikaty</h3>

                {messages.length > 0 ? messages.map((item, i) => (
                    <div key={i} className="presencemsg">
                        <small>{item.createDateString + " " + item.unitName} </small>
                        <h5>{item.subject}</h5>
                        <p>{item.body}</p>
                    </div>
                ))
                :
                <h5>W tej chwili nie ma żadnych komunikatów</h5>
                }

                <div className="mb-3">
                    <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                </div>
            </div>
        </div>
    )
};

export default Messages;