const dict = {
	pl: {
		menu: {
			activate: "Dodaj do urządzenia",
			login: "Zaloguj się",
			help: "Pomoc",
			password: "Zmiana hasła",
			payments: "Płatności",
			logout: "Wyloguj się"
		},
		buttons: {
			return: "Powrót",
			login: "Zaloguj",
		},
		login: {
			header: "Logowanie",
			recoverPassword: "Nie pamiętam hasła"

        },

	}
};

export default dict;