import React from 'react';
import { Link, useParams } from 'react-router-dom';

import "./privacy.scss";


const Privacy = () => {


    return (
        <div className="privacypolicy">
            <h2>POLITYKA PRYWATNOŚCI APLIKACJI NABÓR - OBECNOŚĆ</h2>
        
            <h3>WSTĘP</h3>
            <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich z aplikacji Nabór-Obecność. </p>
            <p>Biorąc pod uwagę możliwość wprowadzania nowych usług w aplikacji Nabór-Obecność, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego systemu, zastrzegamy sobie prawo do zmiany w polityce prywatności naszego systemu. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.</p>

            <h3>DEFINICJE</h3>
            <p><b>Administrator</b> – placówka oświatowa: przedszkole, szkoła podstawowa oraz szkoła ponadpodstawowa, która decyduje o celach i sposobach przetwarzania danych</p>
            <p><b>Aplikacja Nabór-Obecność</b> – aplikacja umożliwiająca kontrolę i rozliczanie czasu pobytu w przedszkolu oraz posiłków</p>
            <p><b>Dane osobowe</b> – wszelkie informacje, które identyfikują lub w połączeniu z innymi informacjami mogą identyfikować użytkownika, takie jak: imię i nazwisko, adres e-mail, numer telefonu, IP urządzenia użytkownika oraz informacje gromadzone za pośrednictwem plików coockies.</p>
            <p><b>Przetwarzanie</b> – to wszelkie działania wykonywane na danych osobowych, takie jak: zbieranie, przechowywanie, analizowanie czy usuwanie.</p>
            <p><b>Polityka</b> – niniejsza Polityka prywatności.</p>
            <p><b>RODO</b> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.</p>
            <p><b>Użytkownik</b> – to każda osoba fizyczna (np. kandydat do placówki oświatowej, rodzic bądź opiekun prawny), która dokona rejestracji w systemie lub korzysta ze systemu.</p>

            <h3>DANE OSOBOWE</h3>
            <ol>
                <li>Administratorem danych osobowych czyli podmiotem, który decyduje o celach i sposobach przetwarzania danych jest placówka lub placówki oświatowe, do której zostały przekazane dane kandydatów (i ich rodziców lub prawnych opiekunów) ubiegających się o przyjęcie.</li>
                <li>W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi – ustawą o ochronie danych osobowych, ustawie o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktów wykonawczych.</li>
                <li>Dane osobowe przetwarzane są na podstawie dobrowolnej zgody wyrażanej przez Użytkownika.</li>
                <li>Aplikacja Nabór-Obecność realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach poprzez dobrowolne wprowadzenie w aplikacji informacji.</li>
                <li>Dane osobowe przetwarzane są w celu przeprowadzenia procesu rozliczania pobytu dziecka w placówce oświatowej.</li>
                <li>Dane osobowe pozostawione w aplikacji Nabór-Obecność mogą zostać ujawnione organom publicznym lub osobom trzecim, którzy zgłoszą stosowne żądanie, na podstawie przepisów prawa</li>
                <li><b>Okres przechowywania danych</b> w aplikacji Nabór-Obecność będą przetwarzane co do zasady:
                    <ol className="b">
                        <li>do czasu zakończenia świadczenia usługi, w szczególności do czasu usunięcia konta</li>
                        <li>do czasu wycofania wyrażonej zgody (o ile zgoda była jedyną podstawą prawną)</li>
                        <li>do momentu ustania uzasadnionego interesu administratora (np. okres przedawnienia roszczeń) lub do czasu zgłoszenia sprzeciwu</li>
                    </ol>
                    W przypadku braku przesłanki, dane zostaną zanonimizowane lub usunięte.
                </li>
                <li>Osobie, której dane są przetwarzane przysługują następujące uprawnienia:
                    <ol className="b">
                        <li><b>prawo do informacji o przetwarzaniu danych osobowych</b> – przekazanie informacji o przetwarzanych danych, takich jak: cel i podstawa prawna, zakres posiadanych danych, podmiotach którym dane są ujawniane oraz planowanym terminie ich usunięcia</li>
                        <li><b>prawo do sprostowania</b> – możliwość poprawiania danych osobowych w przypadku, gdy są one nieprawidłowe lub niekompletne</li>
                        <li><b>prawo do usunięcia danych</b> – możliwość usunięcia danych, gdy:
                            <ul>
                                <li>dane nie są już niezbędne dla celów, dla których zostały zebrane;</li>
                                <li>wycofano zgodę na przetwarzanie danych i był to jedyna podstawa przetwarzania;</li>
                                <li>zgłoszono skuteczny sprzeciw wobec przetwarzania danych;</li>
                                <li>dane będą przetwarzane niezgodnie z prawem;</li>
                                <li>wywiązano się z obowiązku wynikającego z przepisu prawa;</li>
                                <li>dane zostały zebrane w związku ze świadczeniem usług drogą elektroniczną oferowanych dziecku;</li>
                            </ul>
                        </li>
                        <li><b>prawo do ograniczenia przetwarzania</b> – możliwość żądania ograniczenia przetwarzania danych, gdy:
                            <ul>
                                <li>dane są nieprawidłowe, wówczas ograniczenie przetwarzania nastąpi przez okres niezbędny na zweryfikowanie ich poprawności;</li>
                                <li>przetwarzanie danych jest niezgodne z prawem;</li>
                                <li>dane będą potrzebne do obrony lub dochodzenia roszczeń;</li>
                                <li>zostanie wniesiony sprzeciw</li>
                            </ul>

                        </li>
                        <li><b>prawo do wycofania zgody</b> – możliwość wycofania  zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania dokonanego przed wycofaniem zgody.</li>
                        <li><b>prawo do wniesienia skargi</b> – możliwość wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa).</li>
                    </ol>
                </li>
                <li>Dane osobowe, które przetwarzamy na potrzeby procesu rekrutacyjnego kandydatów (ich rodziców lub prawnych opiekunów) ubiegających się o przyjęcie do placówki lub placówek oświatowych pochodzą od administratora danych, a więc od placówki lub placówek oświatowych, do której zostały przekazane dane kandydatów (ich rodziców lub prawnych opiekunów)  przez Użytkownika korzystającego ze systemu.</li>
            </ol>


            <h3>PLIKI COOCKIES</h3>
            <p>Pliki Cookies to niewielkie pliki tekstowe wysyłane i instalowane na urządzeniu Użytkownika przeglądającego aplikację Nabór-Obecność. Cookies zbiera informacje ułatwiające korzystanie ze strony internetowej, np. poprzez zapamiętywanie odwiedzin Użytkownika w systemie.</p>
            <p>Pliki Cookies wykorzystywane w systemie Nabór obejmują „niezbędne” pliki cookies, wykorzystywane do usług wymagających uwierzytelniania w ramach Aplikacji Nabór-Obecność,</p>


            
        </div>
    )
}

export default Privacy;
