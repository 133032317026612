import React, { useEffect, useState, useRef } from 'react';
import { useFetch } from 'hooks';

import Child from "./Child"
import Loading from 'components/loading/Loading';
import Empty from 'pages/empty/Empty';
import { useAppContext } from "hooks/appContext"
import Modal from "react-bootstrap/Modal";
import ErrorMsg from 'components/errormsg/ErrorMsg';


import { apiDelete } from "data/Api";



const Home = () => {
    const { loggedin, children, appendChildren, getChild, removeChild } = useAppContext();
    const { loading, data, error } = useFetch(loggedin ? "/api/Children" : null);
    const [showRemoveConfirm, setRemoveConfirm] = useState(false);
    const [selChilId, setSelChilId] = useState(null);
    const [removeError, setRemoveError] = useState(false);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!data) return;
        if (!hasFetchedData.current) {
            appendChildren(data);
            hasFetchedData.current = true;
		}
    }, [data, appendChildren] );



    function onRemove(childid) {
        setSelChilId(childid);
        setRemoveConfirm(true);
    }


    const handleNo = () => {
        return setRemoveConfirm(false);
    }


    const success = () => {
        removeChild(selChilId);
        setRemoveConfirm(false);
    }

    const erorr = () => {
        setRemoveError(true);
        setRemoveConfirm(false);
    }

    const handleYes = () => {
        let child = getChild(selChilId);
        let account = child.account;

        if (account != null && account.length > 0) {
            apiDelete("/api/child/Delete/" + selChilId, success, erorr);
        }
        else {
            removeChild(selChilId);
            setRemoveConfirm(false);
        }
    }


    if (loading)
        return <Loading />

     if (error) {
        if (error === 401 && children.length === 0)
            return <Empty />

    }
    else if (children.length === 0)
        return <Empty />

    return (
        <>
            {removeError ?
                <>
                    <ErrorMsg msg="Usunięcie nie powiodło się." icon="genError" />
                    <div className="mt-3">
                        <button id="btn-return" className="btn btn-return" onClick={() => setRemoveError(false)}>Zamknij</button>
                    </div>
                </>
                :
                <div className="container presence-container">
                    {children.map((item, i) => (
						<Child
							key={i}
							loggedin={loggedin}
							{...item}
							onRemove={() => onRemove(item.childid)} />
                    ))}
                </div>
            }

   
            <Modal show={showRemoveConfirm}>
                <Modal.Header>
                    <Modal.Title>Potwierdź</Modal.Title>
                </Modal.Header>
				<Modal.Body>Czy na pewno chcesz usunąć?<br />Usunięcie z konta spowoduje utratę możliwości kontroli obecności do momentu ponownej rejestracji.</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleNo}>Nie</button>
                    <button className="btn btn-primary" onClick={handleYes}>Tak</button>
                </Modal.Footer>
            </Modal>
         </>
    )
};

export default Home;