import React from 'react';
import { Link } from 'react-router-dom';

import 'assets/styles/person.scss';
import "./Child.scss";

const Child = ({ loggedin, adult, name, sex, childid, account, checkInAndOutEnabled, onRemove }  ) => {



    function removeChild(e) {
        e.preventDefault();
        onRemove();
    }



    return (
        <div className="childItem" id={"ch" + childid }>
            <div className="dropdown">
                <a href={"#ch" + childid } id="dropdownMenuLink" className="child-menu"  data-toggle="dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" alt="Akcje" title="Akcje"> </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {loggedin && account && account.length > 0 &&
                        <li>
                            <Link className="dropdown-item" to={"/Kalendarz/" + childid} title="Kalendarz">Kalendarz</Link>
                        </li>
                    }
                    {loggedin && account && account.length > 0 &&
                        <li>
                            <Link className="dropdown-item" to={"/Platnosci/" + childid} title="Płatności">Płatności</Link>
                        </li>
                    }
                    <li>
                        <Link className="dropdown-item" to={"/Jadlospis/" + childid} title="Jadłospis">Jadłospis</Link>
                    </li>
                    <li>
                        <a className="dropdown-item deleteChild" href={"#ch" + childid} alt="Usuń z listy" onClick={removeChild}>Usuń</a>
                    </li>
                </ul>
            </div>
			<Link className={adult ? "person adult" : sex === 1 ? "person male" : "person female"} to={checkInAndOutEnabled && !adult ? "/Wejscie/" + childid : "/Kalendarz/" + childid}></Link>
            {loggedin && account && account.length > 0 && <Link className="show-history" to={"/Kalendarz/" + childid}></Link>  }
            {loggedin && account && account.length > 0 && <Link className="show-meals" to={"/Jadlospis/" + childid} title="Jadłospis"></Link>}
            <Link className="name" to={"/Wejscie/" + childid}>{name}</Link>
        </div>
      
    )
};

export default Child;