import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "assets/images/nab_logo_obecnosc.png";
import { getFromApi } from "data/Api";

import MainMenu from "./mainmenu";

import "./Header.scss";
import { useAppContext } from "hooks/appContext"


const Header = (props) => {
    const LocalStrorageKey = "NaborLasMessage";
    const { loggedin, dict } = useAppContext();
    const [messagesCount, setMessagesCount] = useState(0);

    const menuItems = [
        { text: dict.menu.activate, toAuthenticated: null, path: "/Aktywacja" },
        { text: dict.menu.payments, toAuthenticated: true, path: "/Platnosci" },
        { text: dict.menu.login, toAuthenticated: false, path: "/Logowanie" },
        { text: dict.menu.password, toAuthenticated: true, path: "/Haslo" },
        { text: dict.menu.help, toAuthenticated: null, path: "/Pomoc" },
    ];


    function apiError(error) {
    }

    function apiSuccess(data) {
        setMessagesCount(data > 0 ? data : 0);
    }



  

    useEffect(() => {
        const getMessagesCount = () => {
			let maxId = localStorage.getItem(LocalStrorageKey);
			if (maxId == null) maxId = 0;
            if (loggedin) {
                getFromApi("/api/MessagesCount?from=" + maxId, apiSuccess, apiError);
            }
        };
        getMessagesCount();

        const intervalID = setInterval(getMessagesCount, 30000);


        return () => clearInterval(intervalID);
    }, [loggedin]);

    return (
        <header>
            <nav className="navbar">
                <Link id="presence" to="/">
                    <img src={logo} alt='Logo' />
                </Link>
                <div className="float-right dropdown" id="presence-menu">
                    {loggedin &&
                        <>
                        <Link id="toMessages" to="/Komunikaty"><span></span></Link>                        
                        <span className="badge">{messagesCount > 0 ? messagesCount : null}</span>
                        </>
                    }
                    <MainMenu items={menuItems} loggedin={loggedin}/>
                </div>
            </nav >
        </header >
    )
};

export default Header;