import * as React from 'react';

const Payments = () => (
    <div className="container">
        <h3>Instrukcja systemu kontroli obecności w przedszkolu</h3>
        <p>
            <b>Rejestracja urządzenia:</b> po uruchomieniu aplikacji lub strony w przeglądarce po raz pierwszy należy wykonać jednorazowo proces rejestracji urządzenia.  Polega on na zapamiętaniu danych rejestracyjnych w twoim urządzaniu i powiązaniu ich z twoim dzieckiem uczęszczającym do przedszkola.
        </p>
        <p>
            Aby zarejestrować urządzenie należy podać dane otrzymane w twojej placówce: kod aktywacyjny, identyfikator placówki oraz identyfikator dziecka.  W przypadku gdy do przedszkola uczęszcza więcej niż jedno twoje dziecko należy wybrać z menu „Dodaj kolejne dziecko” i podać podobne dane dla drugiego dziecka.
        </p>
        <p>
            Proces rejestracji urządzania konieczny jest do późniejszego zgłaszania momentu przyprowadzenia i odebrania dziecka z przedszkola. Najlepiej wykonać go na telefonie, który ma również funkcję GPS
        </p>
        <p>
            Dane aktywacyjne można wykorzystać na wielu urządzeniach (telefonach) najlepiej na wszystkich należących do osób odprowadzających i przyprowadzających dzieci. Dane te należy chronić przed dostępem osób nie upoważnionych do odbierania i przyprowadzania dzieci.
        </p>
        <p>
            <b>Zakładanie konta:</b> podczas rejestracji urządzania można opcjonalnie założyć konto dostępowe podając email jako login oraz swoje wybrane hasło. Dzięki temu będziemy mieli dostęp do danych o godzinach przyjścia i wyjścia z przedszkola twojego dziecka za dowolny okres  oraz dostęp do możliwości zgłaszania nieobecności dziecka w danym dniu czy terminie (np. z powodu choroby czy wyjazdu).
        </p>
        <p>
            Dla każdego dziecka może być założone tylko jedno konto. Jeżeli dla podanych danych konto już wcześniej zostało założone to rejestracja kolejnego konta się nie uda
        </p>
        <p>
            <b>Odprowadzania i odbieranie dzieci:</b> aby zaznaczyć wejście lub wyjście dziecka należy uruchomić aplikację (lub wejść na stronę) wybrać dziecko i kliknąć w zależności od okoliczności przycisk Wejście lub Wyjście. Aplikacja sprawdzi czy jesteś w tym momencie na terenie przedszkola. Niezbędne jest włączenie lokalizacji GPS w telefonie (tak samo jak przy korzystaniu z nawigacji samochodowej). Jest to konieczne tylko w momencie kliknięcia przycisku Wejście lub Wyjście. Po tej akcji można od razy wyłączyć lokalizację w telefonie.
        </p>
    </div>
);

export default Payments;