import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)

	.init({
		fallbackLng: ['pl'],
		debug: false,
		defaultNS: 'default',
		ns: ['default', 'login'],

		load: 'languageOnly',

		backend: {
			loadPath: function (lng, ns) {
				return `/locales/${lng}/${ns}.json`;
			},
		},

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;