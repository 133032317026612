import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="container">
        <h1>404 - Strona nie znaleziona!</h1>
        <Link to="/">
            Start
        </Link>
    </div>
);

export default NotFound;