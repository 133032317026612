import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { apiPost } from "data/Api";


import './RestorePassword.scss'

const RestorePassword = () => {
    const { register, formState: { errors }, handleSubmit, watch, setFocus } = useForm();
    const [result, setResult] = useState(false);


    useEffect(() => {
       setFocus("email")
    }, [setFocus])


    function apiError(status) {
      
    }

    function apiSuccess(data) {
        console.log('apiSuccess ' + JSON.stringify(data));
        setResult(data);
    }

    function onSubmit(data) {
        console.log(data);

        apiPost("/api/RestorePassword", data, apiSuccess, apiError);
    }

    const emailRegister = register("email", {
        required: "Nie podano adresu email",
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            message: "Podaj prawidłowy adres email"
        }
    });

    return(
        <div className='main-wrapper'>
            <div className='container presence-container'>
                { result ?
                    <div>
                        <p className='mt-3 mb-3'>Jęzeli został podany prawidłowy e-mail to została wysłana wiadomość z linkiem do ustawienia nowego hasła.</p>
                        <p>Sprawdź swoją skrzynka e-mail.</p>
                        <p>Link jest ważny 24h.</p>

                        {result.link &&
                            <div>
                                <b>Link widoczny tylko na systemie testowym</b>
                                <p><a href={result.link}>{result.link}</a></p>
                            </div>
                        }

                        <div className="mb-3">
                            <Link to="/Logowanie" id="btn-return" className="btn btn-return">Powrót</Link>
                        </div>
                    </div>
                    :
                    <form className='form-narrow' onSubmit={handleSubmit(onSubmit)} >
                        <h4 className='mt-3 mb-3'>Odzyskaj dane logowania</h4>
                        <p>Na podany e-mail zostanie wysłana wiadomość z linkiem do ustawienia nowego hasła</p>
                        <input type="text"
                            name="email"
                            placeholder="Adres email"
                            {...emailRegister}
                            className="form-control"
                            autoComplete="new-email"
                            aria-required="true"
                        />
                        <div className="error mb-3">
                            {errors.email && errors.email.message}
                        </div>
                        <div className="mb-3">
                            <button type="submit" id="btnLogin" className="btn btn-primary" disabled={watch("email") === '' ? true : false} >Wyślij</button>
                        </div>
                        <div className="mb-3">
                            <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
};

export default RestorePassword;



