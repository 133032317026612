export function getFromApi(url, success, error) {
    fetch(url)
        .then(data => {
            if (!data.ok) {
                const errorMessage = { status: data.status };
                throw errorMessage;
            }
            return data.json()
        })
        .then(data => success(data))
        .catch((err) => {
            error(err.status);
        });
}



export function apiPost(url, data, success, error) {
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(data => {
        if (!data.ok) {
            const errorMessage = { status: data.status };
            throw errorMessage;
        }
        return data.json()
    })
    .then(data => {
        success(data);
    })
    .catch((err) => {
        error(err.status);
    });
}

export function apiDelete(url, success, error) {
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: ""
    })
        .then(data => {
            if (!data.ok) {
                const errorMessage = { status: data.status };
                throw errorMessage;
            }
            return data.json()
        })
        .then(data => {
            success(data);
        })
        .catch((err) => {
            error(err.status);
        });
}