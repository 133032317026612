import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/header/Header';
import PageHeader from 'components/pageheader/PageHeader';
import { useFetch } from 'hooks';
import { useAppContext } from "hooks/appContext"

const LayoutWrapper = (props) => {
    const { data  } = useFetch("/api/SignIn");
    const { setLoggedin } = useAppContext();
    const hasFetchedData = useRef(false);


    useEffect(() => {
        if (!data)
            return;
        if (!hasFetchedData.current) {
            setLoggedin(data.success);
            hasFetchedData.current = true;
        }
    }, [data, setLoggedin]);

    function Content() {
        return (<>{props.children}</>);
    }


    return (
        <>
            <Header />
            <PageHeader />
            <div className="container">
                {Content()}
            </div>
            <div className="container footer">
                <Link to="/politykaprywatnosci">polityka prywatności</Link>
            </div>
        </>
    )
};

export default LayoutWrapper;