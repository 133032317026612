import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "hooks/appContext"

export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    return [
        { value, onChange: e => setValue(e.target.value) },
        () => setValue(initialValue)
    ]; 
};


export function useFetch(url) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!url) {
            setLoading(false);
            return;
        }
        fetch(url)
            .then(data => {
                if (!data.ok) {
                    setError(data.status)
                    return null;
                }
                return data.json()
            })
            .then(setData)
            .then(() => setLoading(false))
            .catch( setError );
    }, [url]);

    return {
        loading,
        data,
        error
    };
}


export function useAuthentication() {
    const history = useHistory();
    const { loggedin } = useAppContext();

    useEffect(() => {
        if (!loggedin) {
            history.push("/Logowanie");
        }
    }, [history, loggedin]);


    return {
    };
}