import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { useAppContext } from "hooks/appContext";
import { getFromApi } from "data/Api";


import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import "./meals.scss";


const Meals = () => {
    let { childid } = useParams();

    const [day, setDay] = useState(0);
    const [meals, setMeals] = useState({});
    const { getChild } = useAppContext();
    const hasFetchedData = useRef(false);

    const date = moment().add(day, 'd');


    function prevDay(e) {
        e.preventDefault();
        hasFetchedData.current = false;
        setDay(day - 1);
    }

    function nextDay(e) {
        e.preventDefault();
        hasFetchedData.current = false;
        setDay(day + 1);
    }

    function apiError(error) {
        console.log("error: " + error);
    }

	function apiSuccess(data) {
        setMeals(data.meals);
    }

    useEffect(() => {
        if (!hasFetchedData.current) {
            const child = getChild(childid);
            getFromApi("/api/Menu?day=" + day + "&u=" + child.unitid, apiSuccess, apiError);
            hasFetchedData.current = true;
        }
    }, [childid, day, getChild]);

    return (
        <div className="main-wrapper">
            <div className="container presence-container">
                <div id="manupage-header2">
                    <span>Jadłospis</span>
                </div>
                <div id="manupage-day">
                    <a href="#!" onClick={prevDay}><FaChevronLeft/></a>
                    <span> <Moment locale="pl" format="dddd, DD MMMM YYYY" add={{ hours: 1 }}>{date}</Moment></span>
                    <a href="#!" onClick={nextDay}><FaChevronRight /></a>
                </div>

				{!meals || !(meals.breakfast || meals.lunch || meals.snack || meals.dinner)
                    ? <div className="meal-item">Na ten dzień nie wprowadzono jadłospisu</div>
                    :
					<div>
						{meals.breakfast && 
							<div className="meal-item">
								<span>{meals.breakfastName}: </span><p>{meals.breakfast}</p>
							</div>
						}
						{meals.lunch && meals.mealCount > 1 &&
							<div className="meal-item">
								<span>{meals.lunchName}: </span><p>{meals.lunch}</p>
							</div>
						}
						{meals.snack && meals.mealCount > 2 &&
							<div className="meal-item">
								<span>{meals.snackName}: </span><p>{meals.snack}</p>
							</div>
						}
						{meals.dinner &&meals.mealCount > 3 &&
							<div className="meal-item">
								<span>{meals.dinnerName}: </span><p>{meals.dinner}</p>
							</div>
						}
                    </div>
                }

                <div className="mb-3">
                    <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                </div>
            </div>
        </div>
    )
};
export default Meals;