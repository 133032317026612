const LocalStrorageKey = "NaborDeviceTokens";

const loadJSON = key => {
    let val = localStorage.getItem(key);
    if (val === "undefined")
        val = "[]";
    return key && JSON.parse(val)
};

const saveJSON = (key, data) => localStorage.setItem(key, JSON.stringify(data));


function save(children) {
    saveJSON(LocalStrorageKey, children);
}



const LocalStorageData = {
    load: () => loadJSON(LocalStrorageKey) || [],
    save: (children) => save(children),
}




export default LocalStorageData;