import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from "hooks/appContext"

import "./empty.scss";


const Empty = () => {
    const { loggedin } = useAppContext();

    return (
        <div className="container presence-container">
            <div className="EmptyTokens">
                <h3>Do Twojego urządzenia nie zostało jeszcze dodane żadne dziecko lub osoba dorosła</h3>
				<p>Dodaj dziecko lub osobe dorosłą do swojego konta/urządzenia, zgodnie z otrzymaną instrukcją.</p>
                <Link className="btn btn-primary" to="/Aktywacja">Dodaj</Link>
            </div>
            { !loggedin &&
                <div className="NotRegistered">
                    <h3>Posiadam już konto</h3>
                    <p>Jeżeli wcześniej założyłeś konto poprzez podanie adresu e-mail i hasła, to możesz od razu się zalogować. Wcześniejsza rejestracja urządzenia nie jest w takim przypadku wymagana.</p>
                    <Link className="btn btn-primary" to="/Logowanie">Zaloguj się</Link>
                </div>
            }
        </div>
    )
};

export default Empty;