import { useContext } from "react";
import { LoginContext } from "App"
import localStorageData from 'data/LocalStorageData';
import dict from "lang/dict";

export const useAppContext = initialValue => {
    const { userContext: { loggedin, children}, setUserContext } = useContext(LoginContext);


    function appendNewChild(child) {
        let newObj = {
            token: child.token,
            name: child.name,
            sex: child.sex,
            childid: child.childid,
            unitid: child.unitid,
            account: child.account
        };

        
        if (!children.some(x => x.childid === child.childid)) {
            let newArray = [...children, newObj];
            setUserContext({ loggedin: loggedin, children: newArray });
            localStorageData.save(newArray);
        }
       
    }

    function removeChild(childId) {
        let newArray = children.filter(function (item, index, arr) {
            return item.childid !== childId;
        });

        setUserContext({ loggedin: loggedin, children: newArray });

        localStorageData.save(newArray);
    }

    function getChild(childId) {
        return children.find(element => element.childid === childId);
    }


    function appendChildren(newchildren) {
        // tylko te bez konta zosawianmy i ktre nie sa na liscie newchildren
        const filteredArray = children.filter(function (item, index, arr) {
            return (item.account == null || item.account.length === 0) && !newchildren.find(x => x.childid === item.childid);
        });

        let newArray = [...newchildren, ...filteredArray];
        setUserContext({ loggedin: true, children: newArray });
        localStorageData.save(newArray);
    }

    function clearData(all) {
        let newArray = [];
        if (!all) {
            newArray = children.filter(function (item, index, arr) {
                return !item.account;
            });
        }
        setUserContext({ loggedin: false, children: newArray });
        localStorageData.save(newArray);
    }

    function setLoggedin(logged) {
        setUserContext({ loggedin: logged, children: children });
    }



    return {
        loggedin,
        setLoggedin,
        children,
        addChild: child => appendNewChild(child),
        clearData,
        appendChildren,
        removeChild,
        getChild,
        dict: dict.pl
    };
};


