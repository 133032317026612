import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "hooks/appContext";

const MainMenu = ({ items, loggedin }) => {
    const history = useHistory();
    const { clearData, dict} = useAppContext();

    function Logout(e) {
        e.preventDefault();

        fetch("/api/SignIn", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(data => {
            if (!data.ok) {
                return null;
            }
            return data.json()
        })
        .then(data => {
            clearData(false);
            history.push("/");
        })
        .catch();
    }

    return (
    <span className="dropdown">
        <a href="!#" className="dropdown-toggle3" type="button" id="presence-menu-btn" data-bs-toggle="dropdown" aria-expanded="false">
        </a>
        <ul id="menu-dropdown" className="dropdown-menu dropdown-menu-end" aria-labelledby="presence-menu-btn">
                {items.filter(item => item.toAuthenticated == null || (loggedin && item.toAuthenticated) || (!loggedin && !item.toAuthenticated))
                    .map((item, i) => (
                    <li key={i}>
                        <Link className="dropdown-item" to={item.path}>{item.text}</Link>
                    </li>
                ))}

                {loggedin && <li><hr className="dropdown-divider" /></li>}
                {loggedin && <li><a className="dropdown-item" onClick={Logout} href="!#">{dict.menu.logout}</a></li>}
        </ul>
    </span>)
}

export default MainMenu