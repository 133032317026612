import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useAppContext } from "hooks/appContext"
import { apiPost } from "data/Api";

const Activation = () => {
    const { loggedin, addChild } = useAppContext();
    const [withAccount, setWithAccount] = useState(false);

    const { register, formState: { errors, isSubmitting }, handleSubmit, watch, setError } = useForm();
    const history = useHistory();
    const password = useRef({});
    password.current = watch("password", "");


    function onWithAccountClick() {
        setWithAccount(!withAccount);
    }

    function error(status) {
        let msg = "";

        if (status === 400)
            msg = "Podano nieprawidłowe dane.";

        if (status === 409)
            msg = withAccount ? "Istnieje już konto dla podanego adresu email." : "Osoba z podanym identyfikatorze została już związana z innym kontem";

        setError("btnRegister", {
            type: "manual",
            message: msg,
        });
     
    }

    function success(data) {
        addChild(data);
        history.push("/");
    }


    function onSubmit(data) {
        apiPost(withAccount ? "/api/child/addaccount" : "/api/child/register", data, success, error);
    }


    return (
        <div className="main-wrapper">
            <div className="container presence-container">
                <form className="form-narrow"  onSubmit={handleSubmit(onSubmit)}>
                    <div asp-validation-summary="ModelOnly" className="text-danger"></div>
                    <h3>Dodaj do urządzenia</h3>
                    <p>Podaj dane otrzymane w placówce.</p>

                    <ErrorMessage
                        errors={errors}
                        name="btnRegister"
                        render={({ message }) => <div className="form-error-summary"><p className="error">Wystąpił błąd</p>{message}</div>}
                    />

                    <div className="mb-3">
                        <input {...register("activationCode", { required: "Nie podano kodu aktywacyjnego", minLength: 5 })} className="form-control" placeholder="Kod aktywacyjny" autoComplete="new-email"  aria-required="true" />
                        <div className="error">
                            {errors.activationCode?.type === 'required' && errors.activationCode.message}
                            {errors.activationCode?.type === 'minLength' && "Kod aktywacyjny powinien mieć powyżej 5 znaków"}
                        </div>
                    </div>
                    <div className="mb-3">
                        <input {...register("unitid", { required: "Nie podano identyfikatora placówki"})} className="form-control" placeholder="Identyfikator placówki" autoComplete="off"  aria-required="true" />
                        <div className="error">
                            {errors.unitid?.type === 'required' && errors.unitid.message}
                        </div>
                    </div>
                    <div className="mb-3">
                        <input {...register("childid", { required: true })} className="form-control" placeholder="Identyfikator"  aria-required="true" />
                        <div className="error">
                            {errors.childid?.type === 'required' && "Nie podano identyfikatora"}
                        </div>
                    </div>
                    {
                        loggedin &&
                        <div className="mb-3">
                            <div className="form-check">
                                <input  {...register("account")} className="form-check-input" value="true" type="radio" id="radio1" checked/>
                                <label className="form-check-label" htmlFor="radio1">
                                    Dodaj rodzeństwo
                                </label>
                            </div>
                            <div className="form-check">
                                <input {...register("account")} className="form-check-input" value="false" type="radio" id="radio2"  />
                                <label className="form-check-label" htmlFor="radio2">
                                    Dodaj inne dziecko
                                </label>
                            </div>
                        </div>
                    }

                    { !loggedin &&
                        <div className="form-check mb-3">
                            <input className="form-check-input" name="withAccount" type="checkbox" value="true" id="withAccount" onClick={onWithAccountClick} />
                            <label className="form-check-label" htmlFor="withAccount">
                                Rejestruję także konto ( <Link to="/pomoc">Pomoc</Link>)
                            </label>
                        </div>
                    }
                    {  
                        withAccount && !loggedin &&
                        <div id="accountgroup" >
                            <div className="mb-3">
                                <input  {...register("email", { required: "Nie podano adresu email" })} type="email" className="form-control" placeholder="Adres email" autoComplete="new-email" data-rule-email="true" data-msg-email="Proszę podać poprawny adres email" aria-required="true" />
                                <div className="error">
                                    {errors.email?.type === 'required' && errors.email.message}
                                </div>
                            </div>
                            <div className="mb-3">
                                <input {...register("password", { required: "Hasło nie może być puste", minLength: 8 })} type="password" className="form-control" placeholder="Hasło" autoComplete="new-password" aria-required="true" />
                                <div className="error">
                                    {errors.password?.type === 'required' && errors.password.message}
                                    {errors.password?.type === 'minLength' && "Hasło powinno mieć minimum 8 znaków"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <input {...register("password2", {
                                    required: "Nie podano ponownie hasła",
                                    validate: value => value === password.current || "Hasła nie są takie same"
                                })}
                                    type="password" className="form-control" placeholder="Hasło ponownie" autoComplete="off" aria-required="true" />
                                <div className="error">
                                    {errors.password2 && errors.password2.message}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="mb-3">
                        <button type="submit" id="btnRegister" {...register("btnRegister")} disabled={isSubmitting} className="btn btn-primary" >Rejestruj</button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default Activation;

