import React, { useEffect, useState } from 'react';
import "./PageHeader.scss";
import clock from "assets/images/header_clock.png";

const PageHeader = () => {
    const [now, setNow] = useState("");


    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setNow(now.toLocaleTimeString(["pl-PL"], { hour: '2-digit', minute: '2-digit' }));
            setTimeout(updateTime, 10000);
        }
        updateTime();
    }, []);

    return (
        <div className="presence-hdrimage">
            <div id="presence-slide-left">
                <div className="container">
                    ewidencja pobytu<br /><span>{now}</span>
                </div>
            </div>
            <div id="presence-slide-right">
                <img alt="" src={clock} />
            </div>
        </div>
    )
}

export default PageHeader;