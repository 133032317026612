import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { getFromApi } from "data/Api";

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import "./payments.scss";

const Payments = () => {
    let { childid } = useParams();

    const [month, setMonth] = useState(0);
    const [payments, setPayments] = useState(null);

    const date = moment().date(1).add(month, 'M');
    const m = date.month();

    const year = date.year();

    function prevMonth(e) {
        e.preventDefault();
        setMonth(month - 1);
    }

    function nextMonth(e) {
        e.preventDefault();
        setMonth(month + 1);
    }

    function formatMoney(n) {
        if ((n || n === 0) && ((typeof n == "number") || (n instanceof Number))) {
            var c = 2;
            var d = ",";
            var t = "";
            var s = n < 0 ? "-" : "";
            var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "";
            var j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "") + " zł";
        }
        return n;
    }

    function apiError(error) {
        console.log("error: " + error);
    }

    function apiSuccess(data) {
        if (data.success)
            setPayments(data.children);
    }

    useEffect(() => {
        getFromApi("/api/PaymentDetails?month=" + m + "&year=" + year, apiSuccess, apiError);
    }, [childid, m, year]);


    
    return (
        <div className="main-wrapper">
            <div className="container presence-container">
                <div id="manupage-header2">
                    <span>Płatności</span>
                </div>
                <div id="manupage-day">
                    <a href="#!" onClick={prevMonth}><FaChevronLeft /></a>
                    <span> <Moment locale="pl" format="MMMM YYYY">{date}</Moment></span>
                    <a href="#!" onClick={nextMonth}><FaChevronRight /></a>
                </div>

                {!payments
                    ? <div className="meal-item">Nie przygotowano jeszcze płatności za ten miesiąc.</div>
                    :
                    <div>
                        <div id="payInfo">
                            {payments.map((item, i) => {
           
                                return (
                                <div key={i}>
                                    <h3>
                                        <center>{item.firstName} {item.lastName}</center>
                                    </h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Opis</th>
                                                <th>Liczba</th>
                                                <th>Suma</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.presence?.items?.map((e, i) => {
                                                return (
                                                    <tr key={i}><td>{e.name}</td><td>{e.value}</td><td>{formatMoney(e.price)}</td></tr>
                                                )
                                            })}
                                            <tr style={{ backgroundColor: "#EBECED" }}>
                                                    <td><b>{item.presence?.name}</b></td><td></td><td>{formatMoney(item.presence?.summary)}</td>
                                            </tr>
                                            {item.meals?.items?.map((e, i) => {
                                                return (
                                                    <tr key={i}><td>{e.name}</td><td>{e.value}</td><td>{formatMoney(e.price)}</td></tr>
                                                )
                                            })}
                                            <tr style={{ backgroundColor: "#EBECED" }}>
                                                    <td><b>{item.meals?.name}</b></td><td></td><td>{formatMoney(item.meals?.summary)}</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "#d5e1ed" }}>
                                                    <td><b>Razem do zapłaty</b></td><td></td><td>{formatMoney(item.summary)}</td>
                                                </tr>
                                            <tr>
                                                <td></td><td></td><td></td>
                                            </tr>

                                            {item.payments?.items?.map((e, i) => {
                                                return (
                                                    <tr key={i}><td><b>{e.name}</b></td><td>{e.date}</td><td>{formatMoney(e.price)}</td></tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                    <br />
                                    <p><b>{item.accounts.name}</b></p>
                                    <p>{item.unit.name} ({item.unit.address})</p>
                                    {item.accounts.items.map((e, i) => {
                                            return (
                                                <div key={i}>
                                                    <p>{e.name}:</p>
                                                    <p><b>{e.value}</b></p>
                                                </div>
                                        )
                                    })}
                                </div>
                            )})}
                        </div>
                    </div>
                }

                <div className="mb-3">
                    <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                </div>
            </div>
        </div>
    )
};

export default Payments;




//res.settlements.forEach(function (element, index) {
//    if (element) {
//        $("#payInfo2").append("<h3><center>" + element.FirstName + ' ' + element.LastName + "</center></h3><hr/>");
//        var $table = $('<table class="table"></table>');
//        $table.append('<thead><tr><th>Opis</th><th>Liczba</th><th>Suma</th></tr></thead>');
//        var $tbody = $('<tbody></tbody>');
//        if (element.PresenceDiscount !== null)
//            $tbody.append(String.Format('<tr><td>Godziny płatne ze zniżką</td><td>{0}</td><td>{1}</td></tr>', element.TotalOverTime, formatMoney(element.OverTimeCost)));
//        else
//            $tbody.append(String.Format('<tr><td>Godziny płatne</td><td>{0}</td><td>{1}</td></tr>', element.TotalOverTime, formatMoney(element.OverTimeCost)));
//        if (element.LateCheckOuts !== null && element.LateCheckOutCost !== null)
//            $tbody.append(String.Format('<tr><td>Spóźniony odbiór</td><td>{0}</td><td>{1}</td></tr>', element.LateCheckOuts, formatMoney(element.LateCheckOutCost)));

//        if (element.OverPayment > 0)
//            $tbody.append(String.Format('<tr><td>Nadpłata (pobyt)</td><td></td><td>{0}</td></tr>', formatMoney(Math.abs(element.OverPayment))));
//        else if (element.OverPayment < 0)
//            $tbody.append(String.Format('<tr><td>Niedopłata (pobyt)</td><td></td><td>{0}</td></tr>', formatMoney(Math.abs(element.OverPayment))));

//        var summary_presence = element.OverTimeCost + (element.LateCheckOutCost || 0) + (-1) * (element.OverPayment || 0);
//        if (summary_presence > 0)
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Do zapłaty za pobyt</b></td><td></td><td>{0}</td></tr>', formatMoney(summary_presence)));
//        else
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Do zapłaty za pobyt</b></td><td></td><td>{0}</td></tr>', formatMoney(0)));

//        if (element.LunchCount !== null && element.SnackCount !== null) {
//            $tbody.append(String.Format('<tr><td>Śniadania</td><td>{0}</td><td>{1}</td></tr>', element.BreakfastCount, formatMoney(element.BreakfastCost)));
//            $tbody.append(String.Format('<tr><td>Obiady</td><td>{0}</td><td>{1}</td></tr>', element.LunchCount, formatMoney(element.LunchCost)));
//            $tbody.append(String.Format('<tr><td>Podwieczorki</td><td>{0}</td><td>{1}</td></tr>', element.SnackCount, formatMoney(element.SnackCost)));
//        } else {
//            $tbody.append(String.Format('<tr><td>Posiłki</td><td>{0}</td><td>{1}</td></tr>', element.BreakfastCount, formatMoney(element.BreakfastCost)));
//        }

//        if (element.MealDiscount !== null)
//            $tbody.append(String.Format('<tr><td>Posiłki łącznie ze zniżką</td><td></td><td>{0}</td></tr>', formatMoney(element.DiscountedMealPrice)));


//        if (element.MealOverPayment > 0)
//            $tbody.append(String.Format('<tr><td>Nadpłata (posiłki)</td><td></td><td>{0}</td></tr>', formatMoney(Math.abs(element.MealOverPayment))));
//        else if (element.MealOverPayment < 0)
//            $tbody.append(String.Format('<tr><td>Niedopłata (posiłki)</td><td></td><td>{0}</td></tr>', formatMoney(Math.abs(element.MealOverPayment))));

//        var summary_meal = element.DiscountedMealPrice + (-1) * (element.MealOverPayment || 0);
//        if (summary_meal > 0)
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Do zapłaty za posiłki</b></td><td>{0}</td><td>{1}</td></tr>', '', formatMoney(summary_meal)));
//        else
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Do zapłaty za posiłki</b></td><td>{0}</td><td>{1}</td></tr>', '', formatMoney(0)));

//        $tbody.append('<tr><td></td><td></td><td></td></tr>');
//        var summary_all = summary_presence + summary_meal;
//        if (summary_all > 0)
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Razem do zapłaty</b></td><td>{0}</td><td>{1}</td></tr>', '', formatMoney(summary_all)));
//        else
//            $tbody.append(String.Format('<tr style="background-color: #EBECED;"><td><b>Razem do zapłaty</b></td><td>{0}</td><td>{1}</td></tr>', '', formatMoney(0)));

//        $tbody.append('<tr><td></td><td></td><td></td></tr>');

//        if (element.Paid > 0 && element.PaidMeals > 0)
//            $tbody.append(String.Format('<tr><td><b>Wpłata za pobyt</b></td><td>{0}</td><td>{1}</td></tr>', element.PaidDate != null ? element.PaidDate : "", formatMoney(element.Paid)));
//        else if (element.Paid > 0)
//            $tbody.append(String.Format('<tr><td><b>Wpłata</b></td><td>{0}</td><td>{1}</td></tr>', element.PaidDate != null ? element.PaidDate : "", formatMoney(element.Paid)));

//        if (element.PaidMeals > 0)
//            $tbody.append(String.Format('<tr><td><b>Wpłata za posiłki</b></td><td>{0}</td><td>{1}</td></tr>', element.PaidMealsDate != null ? element.PaidMealsDate : "", formatMoney(element.PaidMeals)));


//        $table.append($tbody);
//        $('#payInfo2').append($table);
//        $('#payInfo2').append('<br/>');
//        $('#payInfo2').append('<p><b>Dane do płatności:</b></p>');
//        $('#payInfo2').append(String.Format('<p>{0} ({1})</p>', element.UnitName, element.UnitAddress));
//        if (element.Account && element.MealAccount === null) {
//            $('#payInfo2').append('<p>numer indywidualnego konta bankowego do wpłat:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.Account));
//        }
//        else if (element.Account && element.MealAccount !== null) {
//            $('#payInfo2').append('<p>numer indywidualnego konta bankowego do wpłat za pobyt:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.Account));
//        }
//        else if (element.UnitAccount && element.MealUnitAccount === null) {
//            $('#payInfo2').append('<p>numer konta bankowego do wpłat:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.UnitAccount));
//        }
//        else if (element.UnitAccount && element.MealUnitAccount !== null) {
//            $('#payInfo2').append('<p>numer konta bankowego do wpłat za pobyt:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.UnitAccount));
//        }

//        if (element.MealAccount) {
//            $('#payInfo2').append('<p>numer indywidualnego konta bankowego do wpłat za posiłki:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.MealAccount));
//        }
//        else if (element.MealUnitAccount) {
//            $('#payInfo2').append('<p>numer konta bankowego do wpłat za posiłki:</p>');
//            $('#payInfo2').append(String.Format('<p><b>{0}</b></p>', element.MealUnitAccount));
//        }


//    }
//    else {
//        $("#payInfo2").append("<p>nie przygotowano jeszcze płatności za ten miesiąc</p>");
//    }
//});