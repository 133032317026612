import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message';
import { Link, useHistory } from 'react-router-dom';
import { apiPost } from "data/Api";

import './ChangePassword.scss';


const ChangePassword = () => {
    const { register, handleSubmit, watch, formState: { errors }, setError, setFocus } = useForm();
    const history = useHistory();
 
    const password = useRef({});
    password.current = watch("newPassword", "");

    useEffect(() => {
        setFocus('oldPassword')
    }, [setFocus]);


    function apiError(status) {
        setError("oldPassword", {
            type: "manual",
            message: "Nieprawidłowe stare hasło",
        });
    }

    function onSubmit(data) {
        apiPost("/api/Password", data, () => history.push("/"), apiError);
    }

    return (
        <>
            <div className="main-wrapper">
                <div className="container presence-container">
                    
                    <form className="form-narrow" method="post" onSubmit={handleSubmit(onSubmit)}>
                        <h4 className="mt-3 mb-3">Zmiana hasła</h4>

                        <ErrorMessage
                            errors={errors}
                            name="oldPassword"
                            render={({ message }) => <div className="form-error-summary"><p className="error">Wystąpił błąd</p>{message}</div>}
                        />

                        <div className="mb-3">
                            <input
                                type="password"
                                name="oldPassword"
                                className="form-control"
                                placeholder="Stare hasło"
                                {...register("oldPassword", { required: "Stare hasło nie zostało podane" })}
                                autoComplete="new-password"
                                />
                            <div className="error">
                                {errors.oldPassword?.type === "required" && errors.oldPassword.message}
                            </div>
                        </div>

                        <div className='mb-3'>
                            <input
                                type="password"
                                name="Password"
                                className="form-control"
                                placeholder="Nowe hasło"
                                {...register("newPassword", {
                                    required: "Nowe hasło nie zostało podane",
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                        message: "Hasło powinno zawierać co najmniej 1 literę i cyfrę."
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "Hasło powinno zawierać nie mniej, niż 8 znaków."
                                    }                                  
                                })}
                                autoComplete="new-password"
                            />
                            <div className="error">
                                {errors.newPassword && errors.newPassword.message}
                            </div>
                        </div>

                        <div className="mb-3">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Potwierdź nowe hasło"
                                {...register("confirmedNewPassword", { required: "Nowe hasło nie zostało podane", validate: (value) => { return value === password.current; } })}
                               
                                autoComplete="new-password"
                            />
                            <div className="error">
                                {errors.confirmedNewPassword?.type === 'required' && errors.confirmedNewPassword.message}
                                {errors.confirmedNewPassword?.type === 'validate' && "Nowe hasło i jego potwierdznie nie sa takie same"}
                            </div>
                        </div>

                        <div className="mb-3">
                            <button type="submit" id="btnLogin" className="btn btn-primary">Zmień</button>
                        </div>
                        <div className="mb-3">
                            <Link to="/" id="btn-return" className="btn btn-return">Powrót</Link>
                        </div>
                    </form>
                </div>
            </div>
            
        </>
    )
};

export default ChangePassword;