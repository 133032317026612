import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'assets/styles/site.scss';
import 'services/i18next';

import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';


import App from './App';



ReactDOM.render(
    <Suspense fallback={<div>loading</div>}>
        <App />
    </Suspense>
    , document.getElementById('root')
);

